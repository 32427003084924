<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-4">
      <h4>{{question.order}}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <img src="../../assets/icons/info.png" width="30px" height="30px" alt="">
      <p class="fw-bold mb-0">{{this.$store.getters.getUsdText}}</p>
    </div>
    <div class="my-5">
      <div class="form-group position-relative mb-4 wet-form input px-3 ">
        <label class="mb-2 label fw-bold">{{ question.options.text }}</label>
        <br>
        <vue-numeric currency="$"  class="input-currency" separator="." placeholder="$0" v-model="result" @change="result = $event.target.value"></vue-numeric>
<!--        <CurrencyInput ref="investment" class="input-currency" v-model="result"  @change="result = $event" :options="{ currency: 'USD' }"/>-->
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {investmentInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';
import VueNumeric from 'vue-numeric'

Vue.use(VAnimateCss);

export default {
  name: "Investment",
  components: {
    // CurrencyInput: () => import('../CurrencyInput'),
    VueNumeric
  },
  props: {},
  data() {
    return {
      question: investmentInitialData,
      result: '',
    }
  },
  mounted() {
  },
  computed: {
    handleValidation() {
      return !this.result
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return Number(x).toLocaleString();
    },
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    handleInput(e) {
      this.result = e;
    }
  },

}
</script>

<style scoped lang="scss">
.dollar-input {
  width: 50%;
  padding-left: 2.5rem;
}


.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon {
      fill: white;
    }
  }
}

.input-currency {
  width: 50%;
  height: 38px;
  padding: 1rem;
  &:focus-visible {
    outline: none !important;
  }
}

.input {
  width: 100%;
}

.label {
  width: auto;
  font-size: 1.3rem
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

@media (max-width: 700px) {
  .dollar-input {
    width: 100%
  }
}
</style>
